import axios from 'axios';
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  GET_MACHINE,
  GET_DRINKS,
  GET_MACHINE_STATUS,
  GET_ING,
  GET_MAC_VENTAS,
  GET_VENTS_MONTH,
  GET_VENTAS_WEEK,
  GET_VENTAS_WEEKEND,
  FAILED_GET_DRINKS,
  CREATE_INGREDIENTE,
  UPDATE_INGREDIENTE,
  GET_INFO_ALL_USER,
} from './actions-types';

export const reponerStockIngrediente = (nombreIngrediente, cantidadReponer) => async (dispatch) => {
  try {
    const response = await axios.post('/reponer', {
      nombreIngrediente,
      cantidadReponer,
    });
  } catch (error) {}
};

export const addNewIngrediente = (id) => async () => {
  try {
    const response = (await axios.post(`/api/ingredientes/${id}`)).data;
  } catch (error) {}
};

export const dataAllInfoUser = () => async (dispatch) => {
  try {
    const userName = localStorage.getItem('userName');
    const res = (await axios.get(`/dash/${userName}`)).data;
    dispatch({
      type: GET_INFO_ALL_USER,
      payload: res,
    });
  } catch (error) {}
};

export const deleteDrinkg = (id, mac) => async (dispatch) => {
  try {
    const response = (await axios.delete(`/api/drinks/deletedrink/${id}/${mac}`)).data;
    dispatch({
      type: UPDATE_INGREDIENTE,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: FAILED_GET_DRINKS,
      payload: error.response,
    });
  }
};

export const deletedIngrediente = (id) => async (dispatch) => {
  try {
    const response = (await axios.delete(`/api/ingredientes/deleted/${id}`)).data;
    dispatch({
      type: GET_DRINKS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: FAILED_GET_DRINKS,
      payload: error.response,
    });
    console.error(error.response.data.message);
  }
};

export const fetchIngredientes = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/ingredientes/getingredientes/${id}`); // Cambia la URL a tu endpoint correcto
    dispatch({ type: 'FETCH_INGREDIENTES_SUCCESS', payload: response.data });
  } catch (error) {
    console.error('Error al cargar los ingredientes:', error);
  }
};

export const getIngredientes = (id) => async (dispatch) => {
  try {
    localStorage.setItem('TragoId', id);
    const response = (await axios.get(`/api/ingredientes/getingredientes/${id}`)).data;
    console.log('action: ', response);

    dispatch({
      type: GET_ING,
      payload: response,
    });
  } catch (error) {
    console.error(error.response.data.message);
  }
};

export const createIngrediente = (dataCreate) => async (dispatch) => {
  try {
    const response = (await axios.post('/api/ingredientes/create', { dataCreate })).data;
    dispatch({
      type: CREATE_INGREDIENTE,
      payload: response,
    });
  } catch (error) {
    console.error(error.response.data.message);
  }
};

export const UpdateIngredientes = (ingredienteId, cantidad) => async (dispatch) => {
  try {
    const tragoId = localStorage.getItem('TragoId');
    const response = (
      await axios.put(`api/ingredientes/update/${ingredienteId}/${tragoId}`, {
        cantidad,
      })
    ).data;
    dispatch({
      type: GET_ING,
      payload: response,
    });
  } catch (error) {
    console.error(error.response?.data?.message || error.message);
  }
};

export const updateNameMachine = (id, nombre) => async () => {
  try {
    const response = (await axios.put(`/api/update/namemachines/${id}/name`, { nombre })).data;
  } catch (error) {
    console.error(error.response.data.message);
  }
};

export const updateNameDrinks = (id, nombreTrago) => async () => {
  const response = (await axios.patch(`/api/drinks/updatename/${id}`, { nombreTrago })).data;
};

export const adNewPrices = (id, precio) => async () => {
  console.log(id, precio);

  const response = await axios.put(`/api/drinks/newprice`, { id, precio });
};

export const getMachineStatus = (mac) => async (dispatch) => {
  try {
    const response = (await axios.get(`/status/${mac}`)).data;

    dispatch({
      type: GET_MACHINE_STATUS,
      payload: { mac, status: response.status },
    });
  } catch (error) {
    console.error('Error fetching machine status:', error);
  }
};

export const getVentasMonth = (mac) => async (dispatch) => {
  try {
    const response = (await axios.get(`/api/ventas/month/${mac}`)).data;
    dispatch({
      type: GET_VENTS_MONTH,
      payload: response,
    });
  } catch (error) {
    console.error(error.response?.data?.message || error.message);
  }
};

export const getVentasWeek = (mac) => async (dispatch) => {
  try {
    const response = (await axios.get(`/api/ventas/week/${mac}`)).data;

    dispatch({
      type: GET_VENTAS_WEEK,
      payload: response,
    });
  } catch (error) {
    console.error(error.response.data.message);
  }
};

export const getVetasWeekEnd = (mac) => async (dispatch) => {
  try {
    const response = (await axios.get('/api/ventas/weekend', mac)).data;

    dispatch({
      type: GET_VENTAS_WEEKEND,
      payload: response,
    });
  } catch (error) {}
};

export const getMachines = () => async (dispatch) => {
  try {
    const useName = localStorage.getItem('userName');
    const response = (await axios.get(`/api/machines/machines/${useName}`)).data;
    dispatch({
      type: GET_MACHINE,
      payload: response,
    });
  } catch (error) {
    console.error(error.response.data.message);
  }
};

export const getMacVentas = (mac) => async (dispatch) => {
  try {
    const response = (await axios.get(`/api/ventas/getventasmachine/${mac}`)).data;
    dispatch({
      type: GET_MAC_VENTAS,
      payload: response,
    });
  } catch (error) {
    console.error(error.response.data.message);
  }
};

export const getDrinks = (mac) => async (dispatch) => {
  try {
    const response = (await axios.get(`/api/drinks/getdink/${mac}`)).data;

    localStorage.setItem('mac', mac);
    dispatch({
      type: GET_DRINKS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: FAILED_GET_DRINKS,
      payload: error.response,
    });
    console.error(error.response.data.message);
  }
};

export const registerUser = (userName, password) => async (dispatch) => {
  try {
    const res = (await axios.post('/api/user/register', { userName, password })).data;
    localStorage.setItem('token', res.token);
    localStorage.setItem('userName', res.userName);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res,
    });
  } catch (error) {
    console.error(error.response.message);
    dispatch({
      type: LOGIN_FAIL,
      payload: error.response.message,
    });
  }
};

export const loginUser = (userName, password) => async (dispatch) => {
  try {
    const res = await axios.post('/api/user/login', { userName, password });
    localStorage.setItem('token', res.data.token);
    localStorage.setItem('userName', res.data.userName);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    console.error(error.response.data.message);
    dispatch({
      type: LOGIN_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem('token');
  localStorage.removeItem('userName');
  dispatch({
    type: LOGOUT,
  });
};
