import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  UpdateIngredientes,
  deletedIngrediente,
  fetchIngredientes,
  addNewIngrediente,
} from '../../redux/actions'; // Asegúrate de que la acción fetchIngredientes esté definida.
import './ingredientes.css';
const Ingredientes = () => {
  const { id, nombre } = useParams();
  const ingredientes = useSelector((state) => state.Ingredientes);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedIngrediente, setSelectedIngrediente] = useState(null);
  const [formData, setFormData] = useState({
    nombre: '',
    descripcion: '',
    tipo: '',
    stock: '',
    precio: '',
  });
  const [isUpdating, setIsUpdating] = useState(false);

  const handlerNewIngrediente = async () => {
    await dispatch(addNewIngrediente(id));
    await dispatch(fetchIngredientes(id));
  };

  useEffect(() => {
    dispatch(fetchIngredientes(id));
  }, [dispatch, id]);

  const openEditModal = (ingrediente) => {
    setSelectedIngrediente(ingrediente);
    setFormData({
      nombre: ingrediente.nombre || '',
      descripcion: ingrediente.descripcion || '',
      tipo: ingrediente.tipo || '',
      stock: ingrediente.stock || '',
      precio: ingrediente.precio || '',
    });
    setShowEditModal(true);
  };

  const openDeleteModal = (ingrediente) => {
    setSelectedIngrediente(ingrediente);
    setShowDeleteModal(true);
  };

  const handleDelete = () => {
    if (selectedIngrediente && selectedIngrediente._id) {
      dispatch(deletedIngrediente(selectedIngrediente._id));
      setShowDeleteModal(false);
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();
    setIsUpdating(true);
    await dispatch(UpdateIngredientes(selectedIngrediente._id, formData));
    setIsUpdating(false);
    setShowEditModal(false);
  };

  if (!ingredientes || ingredientes.length === 0) {
    return (
      <div style={styles.loadingContainer}>
        <div className="spinner"></div>
        <p>Cargando ingredientes...</p>
      </div>
    );
  }

  return (
    <>
      <div style={styles.container}>
        <button style={styles.backButton} onClick={() => navigate(-1)}>
          ← Volver
        </button>
        <h1>{nombre}</h1>
        <h2 style={{ display: 'flex',  alignItems:'center', justifyContent:'center' }}>Ingredientes</h2>
        <button onClick={handlerNewIngrediente}>Agregar un nuevo Ingrediente</button>
        {ingredientes.map((e) => (
          <div key={e._id} style={styles.ingredienteCard}>
            <h2>{e.nombre}</h2>
            <ul>
              <li>
                <h4>Descripción: {e.descripcion}</h4>
              </li>
              <li>
                <h4>Tipo: {e.tipo}</h4>
              </li>
              <li>
                <h4>Stock: {e.stock}</h4>
              </li>
              <li>
                <span>${e.precio}</span>
              </li>
            </ul>
            <button onClick={() => openEditModal(e)} style={styles.editButton}>
              Editar
            </button>
            <button onClick={() => openDeleteModal(e)} style={styles.deleteButton}>
              Eliminar
            </button>
          </div>
        ))}
      </div>

      {/* Modal Editar */}
      {showEditModal && (
        <div style={styles.modalOverlay}>
          <div style={styles.modal}>
            <h2>Editar Ingrediente</h2>
            <form onSubmit={handleSubmitEdit}>
              <div style={styles.inputGroup}>
                <label htmlFor="nombre">Nombre:</label>
                <input
                  type="text"
                  id="nombre"
                  name="nombre"
                  value={formData.nombre}
                  onChange={handleFormChange}
                  required
                />
              </div>
              <div style={styles.inputGroup}>
                <label htmlFor="descripcion">Descripción:</label>
                <input
                  type="text"
                  id="descripcion"
                  name="descripcion"
                  value={formData.descripcion}
                  onChange={handleFormChange}
                  required
                />
              </div>
              <div style={styles.inputGroup}>
                <label htmlFor="tipo">Tipo:</label>
                <input
                  type="text"
                  id="tipo"
                  name="tipo"
                  value={formData.tipo}
                  onChange={handleFormChange}
                  required
                />
              </div>
              <div style={styles.inputGroup}>
                <label htmlFor="stock">Stock:</label>
                <input
                  type="number"
                  id="stock"
                  name="stock"
                  value={formData.stock}
                  onChange={handleFormChange}
                  required
                />
              </div>
              <div style={styles.inputGroup}>
                <label htmlFor="precio">Precio:</label>
                <input
                  type="number"
                  id="precio"
                  name="precio"
                  value={formData.precio}
                  onChange={handleFormChange}
                  required
                />
              </div>
              <button type="submit" style={styles.confirmButton} disabled={isUpdating}>
                {isUpdating ? <div className="spinner"></div> : 'Actualizar'}
              </button>
              <button
                type="button"
                onClick={() => setShowEditModal(false)}
                style={styles.cancelButton}
              >
                Cancelar
              </button>
            </form>
          </div>
        </div>
      )}

      {/* Modal Eliminar */}
      {showDeleteModal && (
        <div style={styles.modalOverlay}>
          <div style={styles.modal}>
            <h2>¿Estás seguro de eliminar este ingrediente?</h2>
            <p>{selectedIngrediente.nombre}</p>
            <button onClick={handleDelete} style={styles.confirmButton}>
              Sí, eliminar
            </button>
            <button onClick={() => setShowDeleteModal(false)} style={styles.cancelButton}>
              Cancelar
            </button>
          </div>
        </div>
      )}
    </>
  );
};

const styles = {
  container: {
    maxWidth: '1000px',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  backButton: {
    display: 'inline-block',
    width: '120px',
    marginBottom: '20px',
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: '#2c3e50',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease, transform 0.2s ease',
  },
  ingredienteCard: {
    border: '1px solid #ccc',
    padding: '15px',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  editButton: {
    marginTop: '10px',
    backgroundColor: '#3498db',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    padding: '8px 12px',
    cursor: 'pointer',
  },
  deleteButton: {
    marginTop: '10px',
    marginLeft: '5px',
    backgroundColor: '#e74c3c',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    padding: '8px 12px',
    cursor: 'pointer',
  },
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
  },
  modal: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
    textAlign: 'center',
    width: '400px',
  },
  inputGroup: {
    marginBottom: '15px',
    textAlign: 'left',
  },
  confirmButton: {
    backgroundColor: '#27ae60',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    padding: '8px 12px',
    margin: '10px',
    cursor: 'pointer',
  },
  cancelButton: {
    backgroundColor: '#c0392b',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    padding: '8px 12px',
    margin: '10px',
    cursor: 'pointer',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    flexDirection: 'column',
  },
};

// Agrega estos estilos en tu archivo CSS o en un archivo de estilos global
// .spinner {
//   border: 4px solid rgba(0, 0, 0, 0.1);
//   width: 36px;
//   height: 36px;
//   border-radius: 50%;
//   border-left-color: #09f;
//   animation: spin 1s ease infinite;
// }

// @keyframes spin {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }

export default Ingredientes;
