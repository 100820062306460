import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import './MachinesDetails.css';
import {
  getIngredientes,
  updateNameDrinks,
  adNewPrices,
  getDrinks,
  deleteDrinkg,
} from '../../redux/actions';

const MachinesDetails = () => {
  const { mac } = useParams();
  const tragos = useSelector((state) => state.tragos);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [globalLoading, setGlobalLoading] = useState(true); // Iniciar con carga activa

  // Estados para edición
  const [editingName, setEditingName] = useState(null);
  const [editingPrice, setEditingPrice] = useState(null);
  const [newValue, setNewValue] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      setGlobalLoading(true);
      await dispatch(getDrinks(mac));
      setGlobalLoading(false);
    };
    fetchData();
  }, [dispatch, mac]);

  const refreshData = async () => {
    setGlobalLoading(true);
    await dispatch(getDrinks(mac));
    setGlobalLoading(false);
  };

  const handlerDelete = async (id) => {
    if (window.confirm('¿Estás seguro de que deseas eliminar este trago?')) {
      setGlobalLoading(true);
      await dispatch(deleteDrinkg(id, mac));
      await dispatch(getDrinks(mac));
      setGlobalLoading(false);
    }
  };

  const handlerIng = async (id, nombre) => {
    setLoading(true);
    await dispatch(getIngredientes(id));
    await dispatch(getDrinks(mac));
    setLoading(false);
    navigate(`/ingredientes/${id}/${nombre}`);
  };

  const startEditingName = (id, currentName) => {
    setEditingName(id);
    setNewValue(currentName);
  };

  const startEditingPrice = (id, currentPrice) => {
    setEditingPrice(id);
    setNewValue(currentPrice);
  };

  const saveName = async (id) => {
    setGlobalLoading(true);
    await dispatch(updateNameDrinks(id, newValue));
    setEditingName(null);
    setNewValue('');
    await dispatch(getDrinks(mac));
    setGlobalLoading(false);
  };

  const savePrice = async (id) => {
    setGlobalLoading(true);
    await dispatch(adNewPrices(id, newValue));
    setEditingPrice(null);
    setNewValue('');
    await dispatch(getDrinks(mac));
    setGlobalLoading(false);
  };

  return (
    <div className="details-container">
      {/* Spinner global */}
      {globalLoading && (
        <div className="loading-overlay">
          <div className="spinner"></div>
        </div>
      )}

      <button className="back-button" onClick={() => navigate(-1)}>
        Volver
      </button>
      <button className="refresh-button" onClick={refreshData} disabled={globalLoading}>
        {globalLoading ? 'Actualizando...' : 'Actualizar Lista'}
      </button>

      <h1>Tragos de la máquina</h1>

      <div>
        {Array.isArray(tragos) && tragos.length > 0 ? (
          tragos.map((e) => (
            <div key={e._id} className="Cards_containers">
              <div className="Cards_elements">
                {/* Edición de Nombre */}
                {editingName === e._id ? (
                  <div className="floating-input">
                    <input
                      type="text"
                      value={newValue}
                      onChange={(event) => setNewValue(event.target.value)}
                      onKeyDown={(event) => event.key === 'Enter' && saveName(e._id)}
                    />
                    <button style={{ background: 'green' }} onClick={() => saveName(e._id)}>
                      Guardar
                    </button>
                    <button style={{ background: 'red' }} onClick={() => setEditingName(null)}>
                      Cancelar
                    </button>
                  </div>
                ) : (
                  <>
                    <h2>{e.nombre}</h2>
                    <button onClick={() => startEditingName(e._id, e.nombre)}>
                      Cambiar nombre
                    </button>
                  </>
                )}

                <span>
                  {Array.isArray(e.precios) && e.precios.length > 0 ? (
                    e.precios.map((s) => (
                      <div key={s._id}>
                        {/* Edición de Precio */}
                        {editingPrice === s._id ? (
                          <div className="floating-input">
                            <input
                              type="number"
                              value={newValue}
                              onChange={(event) => setNewValue(event.target.value)}
                              onKeyDown={(event) => event.key === 'Enter' && savePrice(e._id)}
                            />
                            <button
                              style={{ background: 'green' }}
                              onClick={() => savePrice(e._id)}
                            >
                              Guardar
                            </button>
                            <button
                              style={{ background: 'red' }}
                              onClick={() => setEditingPrice(null)}
                            >
                              Cancelar
                            </button>
                          </div>
                        ) : (
                          <>
                            <h3>Precio: {s.precio}</h3>
                            <button onClick={() => startEditingPrice(s._id, s.precio)}>
                              Editar precio
                            </button>
                          </>
                        )}
                      </div>
                    ))
                  ) : (
                    <p>No hay precios disponibles</p>
                  )}
                </span>

                <h3>Ingredientes: {Array.isArray(e.ingredientes) ? e.ingredientes.length : 0}</h3>

                <div style={{ display: 'flex', gap: '12px' }}>
                  <button onClick={() => handlerIng(e._id, e.nombre)} disabled={loading}>
                    {loading ? 'Cargando...' : 'Ver Ingredientes'}
                  </button>
                  <button style={{ background: 'red' }} onClick={() => handlerDelete(e._id)}>
                    Eliminar
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <h3>{tragos?.message || 'No se encontraron tragos'}</h3>
        )}
      </div>
    </div>
  );
};

export default MachinesDetails;
