import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './components/Home/Home';
import Login from './components/Login/Login';
import Navbar from './components/navbar/NavBar';
import Footer from './components/footer/Footer';
import Register from './components/regster/register';
import MachineDetails from './components/machinesDetail/MachinesDetails';
import Machines from './components/machines/Machines';
import Policies from './components/Policies/Policies';
import Ingredientes from './components/Ingredientes/Ingredientes';
import Ventas from './components/ventas/Ventas';

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route path="/machines" element={<Machines />} />
        <Route path="/detail/:mac" element={<MachineDetails />} />
        <Route path="/policies" element={<Policies />} />
        <Route path="/ventas/:mac" element={<Ventas />} />
        <Route path="/ingredientes/:id/:nombre" element={<Ingredientes />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
