import React from 'react';
import { useSelector } from 'react-redux';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import './SalesByDate.css';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const SalesByDate = () => {
  const ventasPorFechas = useSelector((state) => state.ventasPorFechas);
  const ventasPorDia = ventasPorFechas?.VentasPorDia || {};
  const ventasPorSemana = ventasPorFechas?.VentasPorSemana || {};
  const ventasPorMes = ventasPorFechas?.ventasPorMes || {};

  // Función para crear datos y desplazar la primera marca
  const crearDatosGrafico = (ventas) => {
    const labels = Object.keys(ventas).sort((a, b) => new Date(a) - new Date(b));
    const data = labels.map((label) => ventas[label]);

    // Agregar punto inicial (0,0) para desplazar la línea
    labels.unshift('');
    data.unshift(0);

    return {
      labels,
      datasets: [
        {
          label: 'Ventas Totales',
          data,
          borderColor: 'rgb(75, 192, 192)',
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          tension: 0.4, // Línea suavizada
        },
      ],
    };
  };

  // Configuración del gráfico para que el eje Y empiece en 0
  const opcionesGrafico = {
    scales: {
      y: {
        beginAtZero: true,
      },
      x: {
        ticks: {
          autoSkip: false, // Evita que se omitan etiquetas
          maxRotation: 45,
          minRotation: 45,
        },
      },
    },
  };

  return (
    <div className="sales-by-date">
      <h3>📅 Ventas por Fecha</h3>

      <h4>Ventas por Día</h4>
      {Object.entries(ventasPorDia).length > 0 ? (
        <div>
          <Line data={crearDatosGrafico(ventasPorDia)} options={opcionesGrafico} />
        </div>
      ) : (
        <p>No hay ventas por día disponibles.</p>
      )}

      <h4>Ventas por Semana</h4>
      {Object.entries(ventasPorSemana).length > 0 ? (
        <div>
          <Line data={crearDatosGrafico(ventasPorSemana)} options={opcionesGrafico} />
        </div>
      ) : (
        <p>No hay ventas por semana disponibles.</p>
      )}

      <h4>Ventas por Mes</h4>
      {Object.entries(ventasPorMes).length > 0 ? (
        <div>
          <Line data={crearDatosGrafico(ventasPorMes)} options={opcionesGrafico} />
        </div>
      ) : (
        <p>No hay ventas por mes disponibles.</p>
      )}
    </div>
  );
};

export default SalesByDate;
